import { get } from 'axios'
// import store from '@/store'
import store from './../../store/index.js'

const BASE_URL = store.state.api.baseUrl

//get
function doBackup () {
    const URI = `${BASE_URL}/api/backups`
    return get(URI)
}

export { doBackup }