<template>
    <base-nav class="navbar-top navbar-dark border-0 border-primary apk-nav-z-index-2"
        id="navbar-main"
        :show-toggle-button="false"
        expand
    >
        <ul class="navbar-nav align-items-center justify-content-between d-none d-sm-flex" style="width:100%;padding-left:0;">
            <!-- navbar-user-notification -->
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0" :position="'left'" :size="'15'">
                    <div class="media align-items-center" slot="title" v-if="ui=='postulant' || ui=='participant'">
                        <div class="avatar avatar-sm bg-white-more-transparent" style="position:relative;">
                            <img
                                alt="Image placeholder"
                                src="/img/icons/apk/logo_social-262x262.png"
                                style="transform: scale(.7)">

                            <span class="notification__count">
                                {{ getNotReadNumber }}
                            </span>
                        </div>
                    </div>

                    <template>
                        <notification-component :order="'0'" :canResize="'no'" :notificationHeight="'500px'" :showTitle="false"></notification-component>
                    </template>
                </base-dropdown>
            </li>
            <!-- end navbar-user-notification -->

            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0" :position="'right'">
                    <div class="media align-items-center" slot="title">
                        <!-- nombre -->
                        <div class="media-body mr-4 d-none d-sm-block">
                            <span class="mb-0 text-sm  font-weight-bold">{{this.$store.state.user.attributes.full_name}}</span>
                        </div>
                        <!-- logo-programs -->
                        <div class="avatar avatar-sm bg-white-more-transparent">
                            <img
                                alt="Image placeholder"
                                src="/img/icons/apk/logo-programs-262x262.png"
                                style="transform: scale(.7)">
                        </div>
                    </div>

                    <template>
                        <intranet-logout />
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>

<script>
import { mapGetters } from 'vuex'
import IntranetLogout from '@/layout/Logout/IntranetLogout.vue'
import NotificationComponent from '@/components/Postulant-Participant/NotificationComponent/NotificationComponent.vue'

export default {
    components: {
        NotificationComponent,
        IntranetLogout
    },

    props: {
        ui: {
            type: String,
            default: 'other'
        }
    },

    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchQuery: ''
        };
    },

    computed: {
        ...mapGetters('notification', [
            'getNotReadNumber'
        ])
    },

    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        toggleMenu() {
            this.showMenu = !this.showMenu;
        }
    }
  };
</script>

<style scoped>
.notification__count {
    position: absolute;
    right: -4px;
    bottom: 0px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 0.6rem;
    text-align: center;
    line-height: 15px;
    background-color: #EF4A81;
    color: white;
}
.apk-nav-z-index-2 {
    z-index: 2!important;
}
</style>