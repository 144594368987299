<template>
    <contracts-list-component 
        :routeNameToContractCreate="route_name_create"
        :routeNameToContractDetails="route_name_details">
    </contracts-list-component>
</template>

<script>
import ContractsListComponent from '@/components/Admin-Employee/Postulant/Contracts/ContractsListComponent.vue'

export default {
  components: { ContractsListComponent },
    name: 'ContractsList',
    data: () => ({
        route_name_create: 'admin-postulants-contract-create',
        route_name_details: 'admin-participants-contract-details'
    })
}
</script>