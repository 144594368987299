<template>
    <intranet-dashboard>
        <template slot="routes">
            <sidebar-item-exact :link="{ name: 'Dashboard', icon: 'display', color: 'magenta', path: { name: 'admin-dashboard' } }"/>
            <sidebar-item :link="{ name: 'Backups', icon: 'folder-symlink-fill', color: 'orange', path: { name: 'admin-backups-backup' } }"/>
            <sidebar-item :link="{ name: 'Buscar', icon: 'search', color: 'primary', path: { name: 'admin-search-user' } }"/>
            <sidebar-item :link="{ name: 'Exportar', icon: 'file-ruled', color: 'green', path: { name: 'admin-export' } }"/>
            <sidebar-item :link="{ name: 'Empleados', icon: 'person-fill', color: 'cielo', path: { name: 'admin-employees-list' } }"/>
            <sidebar-item :link="{ name: 'Administradores', icon: 'person-fill', color: 'green', path: { name: 'admin-admins-list' } }"/>
            <sidebar-item :link="{ name: 'Postulantes', icon: 'person-fill', color: 'contract-1', path: { name: 'admin-postulants-list' } }"/>
            <sidebar-item :link="{ name: 'Participantes', icon: 'person-fill', color: 'contract-payment-1', path: { name: 'admin-participants-list' } }"/>
            <sidebar-item :link="{ name: 'Programas', icon: 'box', color: 'naranja', path: { name: 'admin-programs-seasons' } }"/>
            <sidebar-item :link="{ name: 'Vouchers no válidos', icon: 'receipt', color: 'magenta', path: { name: 'admin-invalidVouchers-list' } }"/>
            <sidebar-item :link="{ name: 'P. Postergados', icon: 'receipt', color: 'cielo', path: { name: 'admin-postponements-list' } }"/>
            <sidebar-item :link="{ name: 'P. Abandonados', icon: 'receipt', color: 'magenta', path: { name: 'admin-abandonments-list' } }"/>
            <sidebar-item :link="{ name: 'Sponsors', icon: 'people-fill', color: 'violeta', path: { name: 'admin-sponsor-list' } }"/>
        </template>

        <template slot="routes-layouts-admin">
            <hr class="my-3">
            <h6 class="navbar-heading text-muted" >Layouts</h6>

            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Contratos', icon: 'layout-text-window-reverse', color: 'violeta', path: { name: 'admin-layouts-contracts-list' } }"/>
            </ul>

            <!--Management-->
            <hr class="my-3">
            <h6 class="navbar-heading text-muted" >Management</h6>

            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Correos', icon: 'file-earmark-text', color: 'primary', path: { name: 'admin-general-emails-list' } }"/>
            </ul>
            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Documentos', icon: 'file-earmark-text', color: 'naranja', path: { name: 'admin-general-documents-list' } }"/>
            </ul>
            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Documentos-VISA', icon: 'file-earmark-text', color: 'cielo', path: { name: 'admin-visa' } }"/>
            </ul>
            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Formularios', icon: 'file-earmark-text', color: 'violeta', path: { name: 'admin-forms' } }"/>
            </ul>
            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Proceso Programa 2023-2024', icon: 'credit-card', color: 'naranja', path: { name: 'admin-general-process' } }"/>
            </ul>
            

            <!--Hot Fix-->
            <hr class="my-3"/>
            <h6 class="navbar-heading text-muted" >Contratos PDF</h6>

            <ul class="navbar-nav pl-0" >
                <sidebar-item :link="{ name: 'Contratos PDF', icon: 'layout-text-window-reverse', color: 'magenta', path: { name: 'admin-hotfix-contracts-pdfs' } }"/>
            </ul>
        </template>
    </intranet-dashboard>
</template>

<script>
import IntranetDashboard from '@/layout/Dashboard/IntranetDashboard.vue';

export default {
    name: 'Admin',
    components: { IntranetDashboard },
    data: () => ({}),
};
</script>
