<template>
    <intranet-dashboard>
        <template slot="routes">
             <sidebar-item-exact :link="{ name: 'Dashboard', icon: 'ni ni-tv-2 text-blue', path: { name: 'notActiveRoles-dashboard' } }"/>
        </template>
    </intranet-dashboard>
</template>

<script>
import IntranetDashboard from '@/layout/Dashboard/IntranetDashboard.vue';

export default {
    components: { IntranetDashboard }
}
</script>