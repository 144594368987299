<template>
    <div
        class="main-content apk-bg-1"
        style="height:100vh;background-image:url('/img/brand/patron.png');background-size:25%;background-position:center;background-repeat:repeat">
        <!-- Navbar -->
        <base-nav
            class="navbar-top navbar-horizontal navbar-dark"
            containerClasses="container "
            expand
        >
            <router-link slot="brand" class="navbar-brand pl-4" to="/">
                <img src="/img/brand/imagotipo-w&t-524x170.png" />
            </router-link>

            <template v-slot="{closeMenu}">
                <!-- Collapse header -->
                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="/img/brand/imagotipo-w&t-524x170.png">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <button type="button"
                                    @click="closeMenu"
                                    class="navbar-toggler"
                                    aria-label="Toggle sidenav">
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Navbar items -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" :to="{ name: 'login'}">
                            <i class="ni ni-key-25 text-primary mr-2"></i>
                            <span class="nav-link-inner--text text-primary">Login</span>
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" :to="{ name: 'register'}">
                            <i class="ni ni-circle-08"></i>
                            <span class="nav-link-inner--text">Users</span>
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" :to="{ name: 'admin-dashboard'}">
                            <i class="ni ni-circle-08"></i>
                            <span class="nav-link-inner--text">Admin</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" :to="{ name: 'employee-dashboard'}">
                            <i class="ni ni-circle-08"></i>
                            <span class="nav-link-inner--text">Employee</span>
                        </router-link>
                    </li> -->
                </ul>
            </template>
        </base-nav>

        <!-- Header -->
        <div class="header pt-16">
            <div class="container py-3 py-md-12">
                <b-row> 
                    <b-col>
                        <h1 class="text-center text-primary apk-text-intranet d-none d-md-block" style="color:#fff">
                            INTRANET APK
                        </h1>
                    </b-col>
                </b-row>
            </div>

            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div> -->
        </div>

        <!-- Page content -->
        <div class="container" style="position:relative;z-index:20;">
            <slide-y-up-transition mode="out-in" origin="center top">
                <router-view></router-view>
            </slide-y-up-transition>
        </div>

        <!-- Footer -->
        <!-- <footer class="py-5"> -->
        <footer class="py-7" style="position:fixed;bottom:0%;left:50%;width:max-content;transform:translateX(-50%);z-index:10">
            <div class="container">
                <b-row>
                    <b-col class="text-center text-muted apk-text-footer">
                        &copy; {{year}} <a href="https://aupairkids.com.pe" class="font-weight-bold mx-1" target="_blank">APK</a>
                        All rights reserved
                    </b-col>
                </b-row>
            </div>
        </footer>
    </div>
</template>
<script>
  import { SlideYUpTransition } from 'vue2-transitions'

  export default {
    name: 'Page',
    components: {
      SlideYUpTransition
    },
    data() {
      return {
        year: new Date().getFullYear(),
        showMenu: false
      }
    }
  }
</script>

<style scoped>
@media (min-width: 768px) {
    .apk-text-intranet {
        font-size: 38px;
    }
}
@media (max-width: 500px) {
    .apk-text-footer {
        font-size: 1rem;
    }
}

</style>