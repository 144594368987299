<template>
    <b-row>
        <b-col cols="12" class="text-right mb-4">
            <b-button variant="white" size="sm" @click="reloadData"  class="mr-0 py-2">
                <b-icon icon="arrow-repeat" variant="primary" scale="1.2"></b-icon>
            </b-button>
        </b-col>

        <b-col cols="12" class="mt-5 mt-lg-0">
            <b-card no-body bg-variant="transparent">
                <b-row>
                    <b-col v-for="(img, i) in emails_images" :key="`${i}-email-img`" cols="12">
                        <b-card :img-src="img.src" text-variant="primary" img-bottom class="shadow" :img-alt="img.title" no-body></b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "GeneralProcessComponent",
    data: () => ({
      emails_images: [
        { src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalProcess/2023-2024-process.jpg', title: 'Entrevista de Inglés'},
      ]
    }),
    methods: {
        reloadData() {
            this.emails_images = [{ src: 'https://intranet-api.aupairkids.com.pe/storage/image/generalProcess/2023-2024-process.jpg', title: 'Proceso 2023-2024'},]
        }
    }
}
</script>