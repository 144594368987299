<template>
    <div class="notification" :id="`notification_${order}`">
        <!-- header -->
        <div class="notification__header" :id="`notification_${order}_header`">
            <h3 class="notification__header__title">
                <span class="text-button" v-if="showTitle">Notificaciones</span>
                
                <span class="notification__header__btns">
                    <!-- <b-button size="sm" variant="primary" style="font-size:0.6rem;padding:0 8px;height: 20px;" class="mr-2">all</b-button> -->
                    <b-button size="sm" variant="primary" @click="reloadData" style="font-size:0.8rem;padding:0 4px;height: 20px;">
                        <pulse-loader :color="'#fff'" :margin="'1px'" :size="5" v-if="ghost_loading"/>
                        <b-icon icon="arrow-repeat" scale="0.8"></b-icon>
                    </b-button>
                </span>
            </h3>
        </div>

        <!-- items -->
        <div class="notification__body" :id="`notification_${order}_body`">
            <!-- loader -->
            <div style="height:250px;" v-if="loading">
                <pulse-loader
                    :color="'#5e72e4'"
                    :margin="'9px'"
                    :size="15"
                    style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
            </div>

            <!-- notifications -->
            <ol class="notification__list" v-if="!loading">
                <li v-for="(not, i) in data" :key="`${i}-not`" @click="noNotifModelMarkAsView(not)" class="notification__list__item" :class="not.attributes.read == 'no' ? 'notification__list__item--not-read' : '' ">
                    <div class="notification__list__item__data">
                        <p class="notification__list__item__data__title">
                            {{ not.attributes.subject }}
                        </p>

                        <p class="notification__list__item__data__content">
                            {{ not.attributes.content }}
                        </p>

                        <div class="notification__list__item__data__more">
                            <p>{{ not.attributes.created_at }}</p>
                            <b-button size="sm" variant="white" @click="routeForDetails(not)" v-if="not.attributes.notif_model_exist" class="text-primary" style="box-shadow:none;font-size:0.75rem" >Detalles</b-button>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
// import { getUserNotifications } from '@/api/User/UserNotifications'
import { updateReadNotification } from '@/api/Notification/Notification'
import { mapState } from 'vuex'

export default {
    name: 'NotificationComponent',
    props: {
        canResize: {
            type: String,
            required: true
        },
        order: {
            type: String,
            required: true
        },
        notificationHeight: { // example : 350px
            type: String,
            default: '500px'
        },
        amount: {
            type: [String, Number], // example: 3, 9, if its null retrieve all data
            default: ''
        },
        showTitle: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({}),

    mounted () {
        if (this.canResize == 'si') {
            window.resize = this.doResize
            this.doResize()
        } else {
            this.setSize()
        }
    },

    destroyed() {
        window.resize = null
    },

    computed: {
        ...mapState('notification', [
            'data',
            'loading',
            'ghost_loading',
        ]),
    },

    methods: {
        doResize () {
                // get the height of postulant calendar and notification header
                let postulant_calendar_offSetH = document.querySelector('#postulant_calendar').offsetHeight
                let notification_header_offSetH = document.querySelector(`#notification_${this.order}_header`).offsetHeight

                // set the height to notification and notification body element
                document.querySelector(`#notification_${this.order}`).style.height = `${postulant_calendar_offSetH}px`
                document.querySelector(`#notification_${this.order}_body`).style.height = `${postulant_calendar_offSetH - notification_header_offSetH}px`
        },
        setSize () {
            // set the height to notification element
            let notification = document.querySelector(`#notification_${this.order}`)
            notification.style.height = this.notificationHeight

            // get the height of notification header
            let notification_header_offSetH = document.querySelector(`#notification_${this.order}_header`).offsetHeight

            // set the height to notification body 
            document.querySelector(`#notification_${this.order}_body`).style.height = `${notification.offsetHeight - notification_header_offSetH}px`
        },
        //

        // @params notification.attributes
        markAsView (pNotification) {
            updateReadNotification(pNotification.id)
                .then(()=> {
                    pNotification.read = 'si'
                })
        },

        // @params notification
        noNotifModelMarkAsView({ attributes }) {
            if(!attributes.notif_model_exist && attributes.read == 'no') this.markAsView(attributes)
        },

        //
        navErr (pErr) {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (pErr.name !== 'NavigationDuplicated' && !pErr.message.includes('Avoided redundant navigation to current location')) {
                // But print any other errors to the console
                console.error(pErr);
            } else {
                location.reload()
            }
        },
        // @params notification
        routeForDetails ({ attributes }) {
            if(attributes.read == 'no') this.markAsView(attributes)

            let uiRedirect = attributes.notif_model.hasParticipant == 'si' ? 'participant' : 'postulant'
            let params = { IdProgramSeasonPostulant: attributes.notif_model.programSeasonPostulant_id }

            if (uiRedirect == 'participant') {
                params.IdProgramSeasonParticipant = attributes.notif_model.programSeasonParticipant_id
                params.IdProgramSeason = attributes.notif_model.programSeason_id
            }

            switch (attributes.notificationable_type) {
                case 'App\\Models\\EnglishInterviewSchedule':
                    params.IdEnglishInterviewSchedule = attributes.notificationable_id
                    this.$router.push({ name: 'postulant-program-interviews-interview-details', params }).catch(err => { this.navErr(err) })
                    break;

                case 'App\\Models\\PostulantVoucher':
                case 'App\\Models\\ContractVoucher':
                case 'App\\Models\\ContractPayment':
                    this.$router.push({ name: `${uiRedirect}-program-payments-vouchers`, params }).catch(err => { this.navErr(err) })
                    break;

                case 'App\\Models\\Contract':
                case 'App\\Models\\HtmlContract':
                    this.$router.push({ name: `${uiRedirect}-program-contracts`, params }).catch(err => { this.navErr(err) })
                    break;

                case 'App\\Models\\ProgramSeasonParticipant':
                    if(attributes.subject == 'Ficha') this.$router.push({ name: 'participant-program-ficha', params }).catch(err => { this.navErr(err) })
                    break;

                case 'App\\Models\\ParticipantDocument':
                    this.$router.push({ name: 'participant-program-documents', params }).catch(err => { this.navErr(err) })
                    break;
            
                default:
                    console.log(`detauls-default-${attributes.notificationable_type}`)
                    break;
            }
        },

        reloadData () {
            this.$store.dispatch('notification/getData')
        }

    },
}
</script>

<style scoped>
.notification {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    /* word-wrap: break-word; */
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
    
    /* border: 1px solid green; */
}
/* notification header */
.notification__header {
    flex: none; /* 0 0 auto */
}
.notification__header__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 12px 0px 12px;
    padding: 0 0 12px 0;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.15);
    color: #5e72e4;
}
.notification__header__btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
/* notification body */
.notification__body {
    flex: auto; /* 1 1 auto */
    height: 100% - 61px;
    overflow-y: auto;

    /* border: 1px red solid; */
}
.notification__list {
    text-decoration: none;
    margin-bottom: 0;
    padding-left: 0;
    font-size: 0.8rem;
}
.notification__list__item {
    position: relative;
    cursor: pointer;
}
.notification__list__item:hover {
    background-color: #5e72e314;
}
.notification__list__item--not-read {
    background-color: #5e72e314;
}
.notification__list__item::before {
    content: '';
    display: block;
    position: absolute;
    height: 40px;
    top: 50%;
    left: -5px;
    border-right: #e9e1e1 10px solid;
    transform: translateY(-50%);
    border-radius: 4px;
}
.notification__list__item.notification__list__item--not-read::before {
    content: '';
    display: block;
    position: absolute;
    height: 40px;
    top: 50%;
    left: -5px;
    border-right: #5e72e4 10px solid;
    transform: translateY(-50%);
    border-radius: 4px;
}

.notification__list__item__data {
    margin: 0 18px;
    padding: 15px 0;
    border-bottom: 1.5px solid rgba(0, 0, 0, .15);
}
.notification__list__item__data__title {
    margin: 0 0 4px 0;
    font-size: 0.8rem;
    font-weight: bolder;
    letter-spacing: 1.1px;
    color: #3d3c3e;
}
.notification__list__item__data__content {
    margin: 0 0 6px 0;
    text-align: justify;
    font-size: 0.75rem;
    color: #727272;
}
.notification__list__item__data__more {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
}
.notification__list__item__data__more p {
    margin-bottom: 0;
    color: #4d4c4c;
    font-size: 0.75rem;
}
.notification__list__item__data__more button {
    padding: 0 8px;
    height: 20px;
}
</style>