<template>
    <program-sponsor-details></program-sponsor-details>
</template>

<script>
import ProgramSponsorDetails from '@/views/Employee/ProgramComponents/ProgramDetails/ProgramSponsors/ProgramSponsorDetails/ProgramSponsorDetails.vue'

export default {
    name: "SponsorDetails",
    components: { ProgramSponsorDetails }
}
</script>