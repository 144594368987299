<template>
    <postponements-list-component :ui="'admin'"></postponements-list-component>
</template>

<script>
import PostponementsListComponent from '@/components/Admin-Employee/Postponements/PostponementsListComponent.vue'

export default {
    components: { PostponementsListComponent },
    data: () => ({})
}
</script>