<template>
    <intranet-dashboard>
        <template slot="routes">
            <sidebar-item-exact :link="{ name: 'Dashboard', icon: 'display', color: 'magenta', path: { name: 'employee-dashboard' } }"/>
            <sidebar-item :link="{ name: 'Agenda', icon: 'calendar', color:'cielo', path: { name: 'employee-schedule-dashboard' } }"/>
            <sidebar-item :link="{ name: 'Postulantes', icon: 'person-fill', color:'contract-1', path: { name: 'employee-postulants-dashboard' } }"/>
            <sidebar-item
                :link="{ name: 'Participantes', icon: 'person-fill', color:'contract-payment-1', path: { name: 'employee-participants-dashboard' } }"
                v-show="hasCulturalAdvisorPermission"
            />
            <sidebar-item :link="{ name: 'Exportar', icon: 'file-ruled', color:'contract-1', path: { name: 'employee-export' } }" 
                v-show="hasCulturalAdvisorPermission"
            />

            <sidebar-item :link="{ name: 'P. Postergados', icon: 'receipt', color: 'cielo', path: { name: 'employee-postponements-list' } }"
                v-show="hasCulturalAdvisorPermission"
            />
            <sidebar-item :link="{ name: 'P. Abandonados', icon: 'receipt', color: 'magenta', path: { name: 'employee-abandonments-list' } }"
                v-show="hasCulturalAdvisorPermission"
            />
        </template>

        <template slot="routes-programs">
            <!--Divider-->
            <hr class="my-3" v-show="hasCulturalAdvisorPermission"/>
            <h6 class="navbar-heading text-muted" v-show="hasCulturalAdvisorPermission">programas</h6 >

            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Work and Travel', icon: 'box', color:'primary', path: { name: 'employee-wat-list' } }"/>
                <sidebar-item :link="{ name: 'Internship', icon: 'box', color:'cielo', path: { name: 'employee-internship-list' } }"/>
                <sidebar-item :link="{ name: 'Trainee', icon: 'box', color:'cielo', path: { name: 'employee-trainee-list' } }"/>
                <sidebar-item :link="{ name: 'Au pair', icon: 'box', color:'naranja', path: { name: 'employee-aupair-list' } }"/>
                <sidebar-item :link="{ name: 'Teach', icon: 'box', color:'magenta', path: { name: 'employee-teach-list' } }"/>
            </ul>

            <!--Managment Divider-->
            <hr class="my-3" v-show="hasCulturalAdvisorPermission"/>
            <h6 class="navbar-heading text-muted" v-show="hasCulturalAdvisorPermission">Management</h6 >

            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Correos', icon: 'credit-card', color: 'primary', path: { name: 'employee-general-emails-list' } }"/>
            </ul>
            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Documentos', icon: 'file-earmark-text', color: 'naranja', path: { name: 'employee-general-documents-list' } }"/>
            </ul>
            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Documentos-VISA', icon: 'file-earmark-text', color: 'cielo', path: { name: 'employee-visa' } }"/>
            </ul>
            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Formularios', icon: 'file-earmark-text', color: 'violeta', path: { name: 'employee-forms' } }"/>
            </ul>
            <ul class="navbar-nav pl-0">
                <sidebar-item :link="{ name: 'Proceso Programa 2023-2024', icon: 'credit-card', color: 'naranja', path: { name: 'employee-general-process' } }"/>
            </ul>
            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Sponsors', icon: 'people-fill', color: 'violeta', path: { name: 'employee-sponsor-list' } }"/>
            </ul>

            <!--Layout Divider-->
            <hr class="my-3" v-show="hasCulturalAdvisorPermission"/>
            <h6 class="navbar-heading text-muted" v-show="hasCulturalAdvisorPermission">Layouts</h6>

            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Contratos', icon: 'layout-text-window-reverse', color: 'violeta', path: { name: 'employee-layouts-contracts-list' } }"/>
            </ul>

            <!--Hot Fix-->
            <hr class="my-3" v-show="hasCulturalAdvisorPermission"/>
            <h6 class="navbar-heading text-muted" v-show="hasCulturalAdvisorPermission">Contratos PDF</h6>

            <ul class="navbar-nav pl-0" v-show="hasCulturalAdvisorPermission">
                <sidebar-item :link="{ name: 'Contratos PDF', icon: 'layout-text-window-reverse', color: 'magenta', path: { name: 'employee-hotfix-contracts-pdfs' } }"/>
            </ul>
        </template>
    </intranet-dashboard>
</template>

<script>
import IntranetDashboard from '@/layout/Dashboard/IntranetDashboard.vue';

export default {
    components: { IntranetDashboard },
    data: () => ({}),
    computed:  {
        hasCulturalAdvisorPermission() {
            return this.$store.getters['user/hasEmployeePermission']('asesor-cultural')
        },
    }
};
</script>
