<template>
    <footer class="footer px-4 bg-transparent">
        <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6">
                <div class="copyright text-center text-lg-left text-muted">
                    © {{year}} <a href="https://www.aupairkids.com.pe" class="font-weight-bold ml-1" target="_blank">APK</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="copyright text-center text-lg-right text-muted">
                    All rights reserved
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
