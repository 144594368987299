<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <!-- Side bar -->
    <side-bar
        :background-color="sidebarBackground"
        short-title="apk-intranet"
        title="apk-intranet"
    >
      <template slot="links">
        <slot name="routes"></slot>
      </template>

      <template slot="links-programs">
        <slot name="routes-programs"></slot>
      </template>

      <template slot="links-layouts-admin">
        <slot name="routes-layouts-admin"></slot>
      </template>
    </side-bar>

    <!-- main content -->
    <div class="main-content" :data="sidebarBackground">
        <!-- navbar -->
        <intranet-navbar :ui="ui"></intranet-navbar>

        <div @click="toggleSidebar">
            <slide-y-down-transition :duration="300" origin="center top" mode="out-in">
                <router-view></router-view>
            </slide-y-down-transition>

            <!-- footer -->
            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
    </div>
  </div>
</template>
<script>
  import IntranetNavbar from '@/layout/Navbar/IntranetNavbar.vue';
  import ContentFooter from '@/layout/ContentFooter.vue';
  import { SlideYDownTransition } from 'vue2-transitions';

  export default {
    components: {
      IntranetNavbar,
      ContentFooter,
      SlideYDownTransition
    },
    props: {
        ui: {
            type: String,
            default: 'other'
        }
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
