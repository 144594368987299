<template>
  <abandonments-list-component :ui="'admin'"></abandonments-list-component>
</template>

<script>
import AbandonmentsListComponent from '@/components/Admin-Employee/Abandonment/AbandonmentsListComponent.vue'
export default {
    components: { AbandonmentsListComponent },
    data: () => ({})
}
</script>
