<template>
    <b-row class="mb-4">
        <b-col cols="12" md="4">
            <!-- card -->
            <b-card class="shadow">
                <!-- card header -->
                <!-- <b-card-header >
                    <b-row class="align-items-center">
                        <b-col>
                            <h3 class="mb-0 text-primary text-button">
                                backups
                            </h3>
                        </b-col>
                    </b-row>
                </b-card-header> -->

                <b-button @click="makeBackup" variant="outline-primary" v-show="!loading">
                    Realizar respaldo
                </b-button>

                <div style="height:50px;" v-show="loading">
                    <pulse-loader
                        :color="'#5e72e4'"
                        :margin="'10px'"
                        :size="20"
                        style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)"/>
                </div>

            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { doBackup } from '@/api/Backup/Backup'

export default {
    name: "Backup",
    data: () => ({
        loading: false
    }),
    methods: {
        makeBackup () {
            this.loading = true
            doBackup()
                .then(() => {
                    console.log('bk - done');
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>